import { createApp } from 'vue';

const mountComponent = (componentName, className, pluginList) => {
	const nodes = document.getElementsByClassName(className);

	for(let node of nodes) {
		let app = createApp(componentName);

		for(let plugin of pluginList) {
			if(Array.isArray(plugin)) {
				if(plugin.length > 1) {
					app = app.use(plugin[0], plugin[1]);
				} else {
					app = app.use(plugin[0]);
				}
			} else {
				app = app.use(plugin);
			}
		}

		app.config.unwrapInjectedRef = true;
		app.mount(node);
	}
}

export default mountComponent;